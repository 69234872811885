<template>
  <div class="presentations template-1">
    <header class="page-header container">
      <h1>{{ translations.tcConversationsList }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <!-- / Page Header -->
      <camp-selector @camp_changed="page_load($event)" :showCamp="false"
        :tooltip="translations.tcTemporarilyChangeCamps" :i18n="translations.components"></camp-selector>
      <!-- / Camp Selector -->

      <section class="list container">
        <a class="btn btn-dark flex-0 mr-2" v-if="conversationsICanSee(secured_controls.export_conversations_button)"
          @click.prevent="downloadReport(exportConversationHistoryRoute, 'conversationslist', 'csv')">{{ translations.tcExportConversations }}</a>
      </section>
      <data-table :fields="dataTableFields" :items="itemsUpcoming" :hiddenItems="hiddenItems" :showExportBtn="false"
        :addItems="addItems" :includeAddButton="conversationsICanSee(secured_controls.add_conversation_button)"
        @addConversation="addConversation" :searchTerm="`conversationsTerm`"
        :title="translations.tcUpcomingConversations" :i18n="translations.components"></data-table>
      <a id="pastconversations" />
      <data-table :fields="dataTableFields" :items="itemsPast" :hiddenItems="hiddenItems" :showExportBtn="false"
        :addItems="addItems" :includeAddButton="false" @addConversation="addConversation"
        :searchTerm="`conversationsTerm`" :title="translations.tcPastConversations"
        :i18n="translations.components"></data-table>
      <footer class="page-footer"></footer>
    </div>
  </div>

</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableConversations.vue'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'
import SecurityBanner from '@/components/SecurityBanner'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'conversations-list',
  mixins: [reportDownloadMixin, translationMixin, securityMixin],
  data() {
    return {
      addItems: {
        display: true,
        text: 'Add Conversation',
        action: 'addConversation'
      },
      dataTableFields: [],
      hiddenItems: {
        display: false
      },
      itemsPast: [],
      itemsUpcoming: [],
      reportName: 'Instructors',
      secured_controls: {
        add_conversation_button: 'c530dd36-6a4b-43c3-8b55-bc5da53644c4',
        export_conversations_button: '3e926567-6c43-4db2-a448-1c293e3172d1'
      },
      translations: {},
      allowPage: true
    }
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      conversations_list: 'conversations/conversations_list',
      conversations_list_past: 'conversations/conversations_list_past',
      conversations_list_upcoming: 'conversations/conversations_list_upcoming',
      exportConversationHistoryRoute: 'conversations/exportConversationHistoryRoute',
      officerToolbarSelected: 'user/officerToolbarSelected',
      otfbCountry: 'conversations/otfbCountry',
      prefCulture: 'user/userPreferredCulture',
    })
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('data-table-conversations', 'security-banner', 'camp-select')
      ]).then(results => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
      })
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      clearConversation: 'conversations/clearConversation',
      loadConversationsByOrgKey: 'conversations/loadConversationsByOrgKey',
      loadExportConversationHistoryRoute: 'conversations/loadExportConversationHistoryRoute',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load(evt) {
      if (!evt) {
        // execute only if evt is undefined
        try {
          if (!this.loading) this.setLoadingStatus(true)
          const payload = {
            org_key: this.officerToolbarSelected.country_state
          }

          await Promise.all([this.loadConversationsByOrgKey(payload)]).then(() => {
            this.loadDataTableItems()
            this.loadExportConversationHistoryRoute(this.officerToolbarSelected.country_state)
          })
        } catch (e) {
          console.error('Error in Instructors.vue, page_load()', e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    async addConversation() {
      await this.clearConversation()
      this.$router.push('/programs/pw/conversations/add')
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddConversation
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.dataTableFields = [
        { key: 'mtg_start_date', label: `${this.translations.tcMtgStartDate}`, sortable: true },
        {
          key: 'meeting_type',
          label: `${this.translations.tcMeetingType}`,
          sortable: true
        },
        { key: 'mtg_title', label: `${this.translations.tcMtgTitle}`, sortable: true },
        { key: 'instructor_name', label: `${this.translations.tcInstructorName}`, sortable: true },
        { key: 'location', label: `${this.translations.tcLocation}`, sortable: false },
        { key: 'attendee_count', label: `${this.translations.tcAttendees}`, sortable: false }
      ]
    },
    loadDataTableItems() {
      if (!this.translations.components || !this.conversations_list) return false
      this.itemsUpcoming = this.conversations_list_upcoming.map(el => {
        return {
          cvs_key: el.cvs_key,
          mtg_key: el.mtg_key,
          mtg_start_date: el.mtg_start_date,
          meeting_type: el.meeting_type,
          mtg_title: el.mtg_title,
          instructor_name: el.instructor_name,
          location: el.location,
          attendee_count: el.attendee_count
        }
      })
      this.itemsPast = this.conversations_list_past.map(el => {
        return {
          cvs_key: el.cvs_key,
          mtg_key: el.mtg_key,
          mtg_start_date: el.mtg_start_date,
          meeting_type: el.meeting_type,
          mtg_title: el.mtg_title,
          instructor_name: el.instructor_name,
          location: el.location,
          attendee_count: el.attendee_count
        }
      }).sort((a, b) => { //sort by date descending
        var textA = a.mtg_start_date.toUpperCase()
        var textB = b.mtg_start_date.toUpperCase()
        return textA > textB ? -1 : textA < textB ? 1 : 0
      })
    },
  },
  mounted() { },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
